var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"element-loading-text":_vm.loadingText,"topActionButtonPrimary":{
    text: 'Submit',
    key: 'submit',
    disabled: !_vm.validationData.formIsValid,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"errorMessageClosed":function($event){_vm.errorMessage = ''},"toolbarSelectItem":_vm.toolbarSelectItem}},[_c('div',{}),_c('custom-alert',{staticClass:"mb-14",attrs:{"title":"Claim Exists","message":_vm.fiservData.policyStatusMessage,"show":!!_vm.fiservData.policyStatus,"dismissible":false,"type":"warning"}}),(_vm.fiservData)?_c('div',[_c('LossFormClaimInfo',{attrs:{"lossFormData":_vm.fiservData}})],1):_vm._e(),(_vm.fiservData)?_c('div',[_c('form-builder',{key:_vm.customKey,ref:"exampleRef",attrs:{"schemas":_vm.claimFormFields,"formValues":_vm.fiservData,"dynamicOptions":_vm.dynamicFormBuilderOptions,"buttons":[]},on:{"formFieldChanged":_vm.formFieldChangedHandler,"validationChanged":function($event){_vm.validationData = $event}}}),_c('div',{staticClass:"flex justify-between mb-4 items-baseline"},[(_vm.fieldsWithIssues.length > 0)?_c('div',{staticClass:"text-red-500"},[_c('p',{staticClass:"text-base"},[_vm._v(" Please fill required fields before submitting(*). ")])]):_vm._e()])],1):_c('ResourceNotFound',{attrs:{"title":"Sorry! Loss Form data not found.","subtitle":"Please try again","actionButtonTitle":"Back to claims","actionRedirectPath":"/claims"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }