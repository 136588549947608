
import Claims from "@/components/lossForm/Claims.vue";
import Vue from "vue";
export default Vue.extend({
  name: "create",
  data() {
    return {
      createdType: "new"
    };
  },
  components: {
    Claims
  },
  computed: {
    policyData(): string {
      return this.$route.params.policyData;
    }
  }
});
