
import { getClaimsDefaults, processPaymentOptionEdit } from "@/helpers/claims";
import * as types from "@/store/mutation-types";
import { claimsFormSchema } from "@/forms/shared/claims";
import { format } from "date-fns";
import {
  claimsMapActions,
  claimsMapGetters,
  claimsMapMutations,
  claimsMapState
} from "@/store/modules/claims";
import Vue from "vue";
import { cloneDeep, get, omit, set } from "lodash";
import { LEGACY_PHP_API_DATE_FORMAT } from "@/helpers/constants";
import { getPHPAPIFormattedDate } from "@/helpers/dateProcessor";
import {
  AtlasClaimRepresentativeOptions,
  FieldAdjusterOptions,
  KindOfLossOptions
} from "@/helpers/constants";
import { IClaimsInfo } from "@/store/modules/claims/types";
import { getLossMortgageAddress } from "@/helpers/lossFormDataProcessor";
import LossFormClaimInfo from "@/components/lossForm/LossFormClaimInfo.vue";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

interface IClaims {
  errorMessage: string;
  receivePaymentOption: string;
  successMessage: string;
  isInsuredFieldsValid: boolean;
  isAgentFieldValid: boolean;
  isPolicyInfoValid: boolean;
  validationData: any;
  fiservData: any;
  today: string;
  lossFormRequestData: IClaimsInfo;
  customKey: number;
  loadingText: string;
}

export default Vue.extend({
  name: "claims",
  components: {
    LossFormClaimInfo,
    CustomAlert
  },
  data(): IClaims {
    return {
      receivePaymentOption: "",
      errorMessage: "",
      successMessage: "",
      isInsuredFieldsValid: false,
      isAgentFieldValid: false,
      isPolicyInfoValid: false,
      validationData: {},
      fiservData: {},
      lossFormRequestData: {
        companyNumber: "",
        policyNumber: "",
        effectiveDate: "",
        companyPrefix: ""
      },
      customKey: 0,
      today: getPHPAPIFormattedDate(new Date()),
      loadingText: ""
    };
  },
  async created() {
    this.setEdit(getClaimsDefaults({}));
    if (!this.getLossFormData) {
      const data = this.claimsPolicyData.split("+");
      const prefix = data[0];
      const policyNumber = data[1];
      const companyNumber = data[2] == "99" ? "20" : data[2];
      const date = data[3];
      this.lossFormRequestData = {
        companyPrefix: prefix,
        policyNumber: policyNumber,
        effectiveDate: date,
        companyNumber: companyNumber
      };
      await this.getLossData(this.lossFormRequestData);
    }
    this.fiservData = this.getLossFormData;
    const { Branch, BranchName } = this.fiservData.lossFormOtherInfo.Coverages;
    set(
      this.fiservData,
      "lossFormOtherInfo.Coverages.FormType",
      `${Branch} - ${BranchName}`
    );
    const agentFullName = `${capitalizeFirstLetter(
      this.getValue(this.$getCurrentUser, "firstName")
    )} ${capitalizeFirstLetter(
      this.getValue(this.$getCurrentUser, "lastName")
    )}`;
    set(this.fiservData, "lossFormOtherInfo.Info.AgContacts", agentFullName);
    set(
      this.fiservData,
      "lossFormOtherInfo.Info.Email",
      this.$getCurrentUser.email
    );
    this.fiservData.mortgageInfo = getLossMortgageAddress(this.fiservData);
    this.fiservData.lossFormRequire["dtSubmitted"] = format(
      new Date(),
      LEGACY_PHP_API_DATE_FORMAT
    );
    this.fiservData.lossFormRequire["policyNum"] = this.fiservData[
      "policyNumber"
    ] = get(this.fiservData, "lossFormOtherInfo.Client.PolicyNum", "") || "";
    this.fiservData.lossFormRequire["formType"] = BranchName;
    this.fiservData.lossFormRequire[
      "refAgentCode"
    ] = this.fiservData.formItem.agentCode;
    set(
      this.fiservData,
      "lossFormOtherInfo.Info.AgentCode",
      this.fiservData.formItem.agentCode
    );
    this.fiservData.role = this.$getCurrentUser.role;
    this.fiservData.agentCode = this.fiservData.formItem.agentCode;
    this.fiservData.site = "ATLAS";

    this.fiservData["companyPrefix"] = this.fiservData.policyNumber.split(
      " "
    )[0];

    this.fiservData.role = this.$getCurrentUser.role;
  },
  props: {
    claimsPolicyData: {
      type: String
    },
    createType: {
      type: String
    }
  },
  methods: {
    ...claimsMapActions(["createClaims", "getLossData"]),
    ...claimsMapMutations({
      editField: types.SET_EDIT_FIELD,
      setEdit: types.SET_EDIT
    }),
    getValue(dataObject: object, key: string): string {
      return get(dataObject, key, "") || "";
    },
    async formFieldChangedHandler({
      key,
      value
    }: {
      key: string;
      value: any;
    }): Promise<void> {
      const dateFields = [
        "lossFormOtherInfo.FormInfo.LossDate",
        "lossFormOtherInfo.Coverages.DateAssigned"
      ];
      if (value instanceof Date || dateFields.includes(key)) {
        const formattedDate = getPHPAPIFormattedDate(value);
        set(this.fiservData, key, formattedDate);
      } else if (key === "lossFormOtherInfo.FormInfo.paymentOption") {
        await processPaymentOptionEdit.bind(this)(key, value, this.fiservData);
      } else if (
        key === "lossFormOtherInfo.FormInfo.ClassId" &&
        value !== "21"
      ) {
        set(this.fiservData, "lossFormOtherInfo.FormInfo.ClaimAntName", "");
        set(this.fiservData, "lossFormOtherInfo.FormInfo.ClaimAntPhone", "");
        set(this.fiservData, "lossFormOtherInfo.FormInfo.ClaimAntAddress", "");
        set(this.fiservData, key, value);
      } else {
        set(this.fiservData, key, value);
      }
      this.fiservData = cloneDeep(this.fiservData);
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "submit":
          await this.submitLossForm();
          break;
        case "cancel":
          this.$router.push("/Claims").catch(() => {});
          break;
      }
    },
    async submitLossForm(): Promise<void> {
      try {
        this.loadingText = "Saving claim. Please wait...";
        const toOmit = ["mortgageInfo"];
        this.fiservData.lossFormRequire.paymentOption =
          get(
            this.fiservData,
            "lossFormOtherInfo.FormInfo.paymentOption",
            ""
          ) || "";
        this.fiservData.lossFormRequire = {
          ...this.fiservData.lossFormRequire,
          ...this.fiservData.formItem
        };
        //formItem.email is same as  lossFormOtherInfo.Info.Email, if not set replace
        if (!this.getValue(this.fiservData, "formItem.email")) {
          set(
            this.fiservData,
            "formItem.email",
            this.getValue(this.fiservData, "lossFormOtherInfo.Info.Email")
          );
        }

        if (!this.getValue(this.fiservData, "lossFormRequire.email")) {
          set(
            this.fiservData,
            "lossFormRequire.email",
            this.getValue(this.fiservData, "lossFormOtherInfo.Info.Email")
          );
        }

        if (!this.getValue(this.fiservData, "lossFormRequire.email")) {
          set(
            this.fiservData,
            "lossFormRequire.email",
            this.getValue(this.fiservData, "lossFormOtherInfo.Info.Email")
          );
        }
        await this.createClaims({ data: omit(this.fiservData, toOmit) });
        this.$appNotifySuccess("Loss Form saved.");
        this.$router.push("/claims").catch(() => {});
      } catch (e) {
        this.errorMessage = e.message || "Error Submitting Claims";
        this.$bugSnagClient.notify(e);
      } finally {
        this.$emit("scrollTop");
        this.loadingText = "";
      }
    }
  },
  computed: {
    ...claimsMapState(["makingApiRequest"]),
    ...claimsMapGetters(["getLossFormData"]),
    dynamicFormBuilderOptions(): any {
      return {
        "lossFormOtherInfo.FormInfo.ClassId": [...KindOfLossOptions],
        "lossFormOtherInfo.Coverages.FieldAssigned": [...FieldAdjusterOptions],
        "lossFormOtherInfo.Coverages.ClaimRepresentative": [
          ...AtlasClaimRepresentativeOptions
        ]
      };
    },
    fieldsWithIssues(): string[] {
      if (this.validationData.fieldsWithErrors) {
        return Object.values(this.validationData.fieldLabels);
      }
      return [];
    },
    claimFormFields(): any[] {
      const claimsSchema = claimsFormSchema(
        this.createType,
        "editing",
        this.getValue(
          this.fiservData,
          "lossFormOtherInfo.Client.OtherAuthConcact"
        ),
        this.getValue(this.fiservData, "lossFormOtherInfo.Client.OtherPhone"),
        this.getValue(
          this.fiservData,
          "lossFormOtherInfo.Coverages.WindExcludeStatement"
        )
      );

      return claimsSchema;
    },
    getLabel(): string {
      let label = "";
      const staticLabel = "MORTGAGE INFO.";
      const dynamicLabel = "| MORTGAGE BILLED";
      const payOrInd = get(this.fiservData, "lossFormOtherInfo.Info.PayorInd");
      if (payOrInd === "1") {
        label = `${staticLabel} ${dynamicLabel}`;
      } else if (payOrInd === "0") {
        label = `${staticLabel} Insure Billed`;
      } else {
        label = staticLabel;
      }
      return label;
    }
  }
});
